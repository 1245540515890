import { FirebaseOptions } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyBtY-WpGMqEWM8FYY27DYfkoegq6-bNJno",
  authDomain: "xcloud-45755.firebaseapp.com",
  projectId: "xcloud-45755",
  storageBucket: "xcloud-45755.appspot.com",
  messagingSenderId: "880054033521",
  appId: "1:880054033521:web:b33b06f5a619dbd85e4fd0",
} as Readonly<FirebaseOptions>;
