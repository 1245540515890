export function useCompanyCSS(customElementId = "company") {
  const elementId = customElementId;
  let element = document.getElementById(elementId) as HTMLLinkElement;

  if (!element) {
    const head = document.getElementsByTagName("head")[0];
    const link = document.createElement("link");
    link.id = elementId;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.media = "all";
    element = head.appendChild(link);
  }

  function setStyleUrlFromCompanyId(id: string) {
    element.href = `${import.meta.env.VITE_APP_API_HOST}/api/companies/${id}/custom-styling`;
  }

  function setStyleURL(url: string) {
    element.href = url;
  }

  return {
    element: element as HTMLLinkElement,
    elementId,
    setStyleUrlFromCompanyId,
    setStyleURL,
  };
}
