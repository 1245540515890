import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";

const locale = useLocalStorage("locale", "nl");
const vueI18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "nl",
  messages,
  // missingWarn: false,
  // fallbackWarn: false,
  datetimeFormats: {
    en: {
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
    },
    nl: {
      long: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
    },
  },
});

watch(
  locale,
  (nv) => {
    if (nv === "nl" || nv === "en") {
      vueI18n.global.locale.value = nv;
    }
  },
  {
    immediate: true,
  }
);

export default vueI18n;
