import { requestToken } from "@xcloud/api";
import { getToken, setToken } from "@xcloud/api";

// Reusable wrapper for the auth token
export function useAuth() {
  const authToken = ref(getToken());

  async function authenticate(username: string, password: string) {
    const { successful, payload } = await requestToken({ username, password });
    if (successful) {
      setAuthToken(payload as string);
    }

    return successful;
  }

  const setAuthToken = (token: string) => {
    authToken.value = token;
    setToken(token);
  };

  const getAuthToken = () => authToken.value;

  return {
    setAuthToken,
    getAuthToken,
    authToken: readonly(authToken),
    authenticate,
  };
}
