<template>
  <IonApp>
    <AppPwaInstallPrompt />
    <!-- <AppReceivePushPrompt v-if="!isNativePlatform" /> -->
    <SocialConnectResultModal />
    <IonRouterOutlet />
  </IonApp>
</template>

<script lang="ts" setup>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { useCompanyCSS } from "./service/useCompanyCSS";
import { useMyInfo } from "./service/useMyInfo";

const { state } = useMyInfo();
const { setStyleUrlFromCompanyId, setStyleURL } = useCompanyCSS();

watchDebounced(state, (nv) => {
  if (nv?.companyId) {
    setStyleUrlFromCompanyId(nv.companyId);
  } else {
    setStyleURL("");
  }
});
</script>

<style scoped lang="css"></style>
