import { Capacitor } from "@capacitor/core";
import { authorizeXCloudSocial, shareSocialPost } from "@xcloud/api";
import { Browser } from "@capacitor/browser";
import { alertController } from "@ionic/vue";

export type SocialPlatform = "linkedin"; // | "facebook";

const sharedPosts = useLocalStorage<Map<string, Date>>("sharedPosts", new Map());

export const useSocialSharePost = () => {
  const { t } = useI18n({
    messages: {
      en: {
        sharePost: "Share this post",
        connectAccount: "Connect account",
        confirmMessage:
          "To complete this action, we need your permission to post media on your behalf. Press Continue to login on LinkedIn and to provide these permissions. After logging in you'll be returned to the app.",
      },
      nl: {
        sharePost: "Deel dit bericht",
        connectAccount: "Verbind jouw account",
        confirmMessage:
          "Om deze actie te voltooien, hebben wij jouw toestemming nodig om media namens jou te posten. Klik op doorgaan om in te loggen bij LinkedIn en om de toestemming te verlenen. Na het inloggen wordt je teruggestuurt naar de app.",
      },
    },
  });

  async function showAuthAlert(platform: SocialPlatform) {
    const permissionAlert = await alertController.create({
      header: t("sharePost"),
      subHeader: t("connectAccount"),
      message: t("confirmMessage"),
      buttons: [
        {
          text: t("Cancel"),
          role: "cancel",
        },
        {
          text: t("Continue"),
          role: "confirm",
        },
      ],
    });

    await permissionAlert.present();

    const { role } = await permissionAlert.onDidDismiss();
    return role === "confirm" ? true : false;
  }

  async function sharePost(postId: string, platform: SocialPlatform) {
    return shareSocialPost(postId, platform);
  }

  async function authorize(platform: SocialPlatform) {
    let url = "";
    if (Capacitor.isNativePlatform()) {
      url = await authorizeXCloudSocial(platform, true);
    } else {
      url = await authorizeXCloudSocial(platform, false);
    }

    // Workaround: Safari doesn't open windows from async functions
    setTimeout(() => {
      Browser.open({ url, windowName: "_top" });
    }, 500);
  }

  return {
    sharePost,
    showAuthAlert,
    authorize,
    sharedPosts,
  };
};
