<template>
  <IonPage>
    <IonHeader class="ion-no-border" mode="md">
      <div class="gradient absolute min-h-[160px] w-full rounded-bl-[50px]" />
      <IonToolbar class="content-padding mt-8 min-h-[90px]">
        <slot name="header" v-bind="{ options }">
          <slot name="backButton">
            <IonButtons slot="start" v-if="options.withBackButton ?? true">
              <IonBackButton :defaultHref="options?.defaultBackButtonHref ?? '/app-modules'" />
            </IonButtons>
          </slot>
          <IonTitle v-if="options?.header">
            {{ options.header.title }}
            <small v-if="options.header.subtitle">{{ options.header.subtitle }}</small>
          </IonTitle>
        </slot>
      </IonToolbar>
      <IonToolbar class="content-padding">
        <slot name="toolbar"></slot>
      </IonToolbar>
    </IonHeader>
    <IonContent :fullscreen="true" class="content-padding">
      <slot></slot>
    </IonContent>
  </IonPage>
</template>

<script setup lang="ts">
export type LayoutOptions = {
  header?: {
    title: string;
    subtitle?: string;
  };
  withBackButton?: boolean;
  defaultBackButtonHref?: string;
};

const props = withDefaults(
  defineProps<{
    options?: LayoutOptions;
  }>(),
  {
    options: () => ({
      withBackButton: true,
      defaultBackButtonHref: "/home",
    }),
  }
);

const { options } = toRefs(props);
const horizontalPadding = "5%";
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
  --color: white;
}

ion-content.content-padding {
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: v-bind(horizontalPadding);
  --padding-end: v-bind(horizontalPadding);
}

ion-toolbar.content-padding {
  --padding-start: v-bind(horizontalPadding);
  --padding-end: v-bind(horizontalPadding);
}

.gradient {
  background: var(--ion-color-primary);
  background: linear-gradient(230deg, var(--ion-color-primary) 0%, var(--ion-color-primary-shade) 100%) 0% 0% no-repeat;
}
</style>
