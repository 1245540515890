import { createRouter, createWebHistory } from "@ionic/vue-router";
import { useAuth } from "~/service/useAuth";
import routes from "~pages";

const vueRouter = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/:pathMatch(.*)*",
      redirect: "/login",
    },
    {
      path: "/connect/linkedin",
      redirect: ({ query }) => {
        return { path: "/home", query };
      },
    },
    {
      path: "/logout",
      redirect() {
        useAuth().setAuthToken("");
        return { path: "/login" };
      },
    },
    ...routes,
  ],
});

vueRouter.beforeEach((to, _, next) => {
  const { authToken } = useAuth();
  if (authToken.value && to.path.includes("login")) {
    next("/app-modules");
  } else if (!authToken.value && !to.path.includes("login")) {
    next("/login");
  } else {
    next();
  }
});

export default vueRouter;
