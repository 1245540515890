<template>
  <Transition>
    <div
      class="fixed bottom-0 z-[99] flex w-full flex-row items-center justify-center rounded-t-xl bg-primary p-4"
      v-if="deferredInstallPrompt && isSmaller('sm')">
      <IonButton slot="icon-only" size="small" color="transparent" @click="dismissPrompt">
        <IonIcon :icon="ionIconClose" />
      </IonButton>
      <span class="pr-2 text-sm text-white"> Voeg de app toe aan je thuisscherm om up-to-date te blijven! </span>
      <IonButton color="dark" size="small" @click="installApp">Toevoegen</IonButton>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { breakpointsTailwind } from "@vueuse/core";

const { isSmaller } = useBreakpoints(breakpointsTailwind);

const deferredInstallPrompt = ref<BeforeInstallPromptEvent | false>(false);
const lastDismissed = useLocalStorage<Date | null>("installPromptDismissedOn", null, {
  serializer: {
    read(value) {
      return value ? new Date(value) : null;
    },
    write(value) {
      return value?.toISOString() ?? "";
    },
  },
});

window.addEventListener("beforeinstallprompt", (e: Event) => {
  e.preventDefault();
  if (!lastDismissed.value || new Date().getTime() - lastDismissed.value.getTime() > 1000 * 60 * 60 * 24 * 30) {
    deferredInstallPrompt.value = e as BeforeInstallPromptEvent;
  }
});

function dismissPrompt() {
  deferredInstallPrompt.value = false;
  lastDismissed.value = new Date();
}

async function installApp() {
  if (deferredInstallPrompt.value) {
    await deferredInstallPrompt.value.prompt();
  }

  dismissPrompt();
}
</script>

<style scoped>
.v-leave-active,
.v-enter-active {
  transition: all 0.3s ease-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
