import { getMyInfo } from "@xcloud/api";

const _useAsyncState = useAsyncState(getMyInfo, undefined, {
  immediate: false,
});

/**
 * Provides a function to get the current user's info.
 */
export const useMyInfo = () => _useAsyncState;
