<template>
  <IonModal :is-open="isOpen" mode="ios">
    <DefaultLayout :header-text="{ title: 'Account koppelen', sub: 'LinkedIn' }" class="relative">
      <AppCard class="py-2 text-center">
        <IonIcon
          :icon="ionIconCheckmarkCircleOutline"
          color="turquoise"
          class="mx-auto mb-2 text-3xl"
          v-if="isSuccess" />
        <IonIcon :icon="ionIconWarningOutline" color="orange" class="mx-auto mb-2 text-3xl" v-else />
        <p v-if="isSuccess">
          <span>
            {{ $t("LinkedInConnectSuccess") }}
          </span>
        </p>
        <p v-else-if="error">
          <span v-if="error === ErrorCode.CANCEL_LOGIN || error === ErrorCode.CANCEL_AUTHORIZE">
            {{ $t("LinkedInConnectFailCancel") }}
          </span>
        </p>
        <p v-else>
          <span>
            {{ $t("LinkedInConnectFailUnknown") }}
          </span>
        </p>
      </AppCard>
      <div class="sticky mt-8 flex w-full flex-col gap-y-2">
        <IonButton shape="round" :disabled="isBusy" v-if="!isSuccess" @click.prevent="retry">
          {{ $t("Retry") }}
        </IonButton>
        <IonButton shape="round" :disabled="isBusy" @click="isOpen = false" href="/home">
          {{ $t("Continue") }}
        </IonButton>
      </div>
    </DefaultLayout>
  </IonModal>
</template>

<script setup lang="ts">
import { Capacitor } from "@capacitor/core";
import { useSocialSharePost } from "~/service/useSocialSharePost";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";

const isOpen = ref(false);
const isSuccess = ref(false);
const error = ref<undefined | string>(undefined);
const isBusy = ref(false);
const { currentRoute } = useRouter();

const { authorize } = useSocialSharePost();

async function retry() {
  isBusy.value = true;
  try {
    await authorize("linkedin");
  } finally {
    isBusy.value = false;
  }
}

enum ErrorCode {
  CANCEL_LOGIN = "user_cancelled_login",
  CANCEL_AUTHORIZE = "user_cancelled_authorize",
}

if (!Capacitor.isNativePlatform()) {
  watchOnce(
    () => currentRoute.value.query,
    (nv) => {
      if (nv["status"]) {
        showModal(nv["status"] as string);
      }
    }
  );
} else {
  App.addListener("appUrlOpen", async (e) => {
    if (e.url.includes("xcloud-app://connect/linkedin")) {
      Browser.close();
      if (e.url.includes("status=success")) {
        showModal("success");
      } else {
        showModal("failed");
      }
    }
  });
}

function showModal(status: string) {
  isOpen.value = true;
  if (status === "success") {
    isSuccess.value = true;
  } else if (status === "failed") {
    error.value = currentRoute.value.query["error"] as ErrorCode;
  }
}
</script>

<style scoped></style>
