import { Plugin } from "vue";
import appRouter from "./vueRouter";
import * as Sentry from "@sentry/vue";
import { Replay } from "@sentry/replay";
import { BrowserTracing } from "@sentry/tracing";

const vueSentryPlugin: Plugin = {
  install(app, options: SentryPluginOptions) {
    const { dsn, siteURL, tracesSampleRate, environment, enableReplays } = options;

    Sentry.init({
      app,
      dsn,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.05,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate,
      environment,
      integrations(integrations) {
        integrations.push(
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(appRouter),
            tracePropagationTargets: ["localhost", siteURL, /^\//],
          })
        );

        if (enableReplays) {
          integrations.push(
            new Replay({
              maskAllText: false,
            })
          );
        }

        return integrations;
      },
    });

    if (environment !== "production") {
      console.info("Initialized Sentry");
    }
  },
};

export type SentryPluginOptions = {
  dsn: string;
  siteURL: string;
  tracesSampleRate: number;
  environment: "development" | "staging" | "production";
  enableReplays: boolean;
};

export default vueSentryPlugin;
